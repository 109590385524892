<template>
  <sale code="SALR"></sale>
</template>

<script lang="ts">
import Sale from '@accessory/pages/stock/out/sale.vue'

export default factory({
  components: { Sale }
})
</script>
